import { Link } from 'gatsby'
import React from 'react'
import Pages from '../enums/pages'
import styled from '@emotion/styled'
import { Pointer } from './pointer'

const HeaderDiv = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  z-index: 3;
`

const HeaderItemContainer = styled.div`
  position: relative;
  color: white;
  font-size: 1.2em;
  height: 35px;
  width: 95px;
  opacity: ${(props: { active: boolean }) => props.active ? 1 : 0.8};
  margin: 0 10px;
  :hover {
    opacity: 1;
  }
`

const HoverBorder = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  &:before {
    border-bottom: 2px solid white;
    width: 85px;
    height: 100%;
    transform-origin: center;
    content: '';
    display: inline-block;
    transform: scaleX(${(props: { active: boolean }) => props.active ? 1 : 0});
    position: absolute;
    left: 5px;
  }
  :hover {
    &:before {
      transform: scaleX(1);
      transition: transform 0.3s;
    }
  }
  z-index: 4;
  cursor: pointer;
`

const HeaderItems = styled.div`
  display: flex;
  padding: 40px 60px;
`

const PointerDiv = styled.div`
  transform: scale(0.25);
  margin-left: -25px;
  :hover {
    animation: pulse 1s ease-in-out infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.25);
    }
    50% {
      transform: scale(0.27);
    }
    100% {
      transform: scale(0.25);
    }
  }
`

const HeaderItem = ({ text, active }: { text: string, active: boolean }) => (
  <HeaderItemContainer active={active}>
    <Link to={`/${text.toLowerCase() == 'home' ? '' : text.toLowerCase()}`} style={{ color: "white" }}>
      <HoverBorder active={active}>{text}</HoverBorder>
    </Link>
  </HeaderItemContainer>
)

const Header = ({ page }: { page: Pages }) => (
  <HeaderDiv>
    <PointerDiv>
      <Pointer />
    </PointerDiv>
    <HeaderItems>
      <HeaderItem text={'Home'} active={page == Pages.Home} />
      <HeaderItem text={'About'} active={page == Pages.About} />
      <HeaderItem text={'Portfolio'} active={page == Pages.Portfolio} />
      <HeaderItem text={'Contact'} active={page == Pages.Contact} />
    </HeaderItems>
  </HeaderDiv>
)

export default Header
