import React from 'react'
import styled from '@emotion/styled'
import Measure from 'react-measure'
import AppContext from '../util/context'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

const ContainerDiv = styled.div`
    height: 100vh;
    overflow: hidden;
`

const Container = ({ children }: { children: JSX.Element[] | string }) => (
    <StaticQuery
        query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              description
            }
          }
        }
      `}
      render={data => (
        <Measure bounds>
            { ({ measureRef, contentRect: { bounds } }) => (
                <ContainerDiv ref={measureRef}>
                    <Helmet title={data.site.siteMetadata.title} meta={[{ name: 'description', content: data.site.siteMetadata.description }, { charSet: 'utf-8' }]}>
                        <html lang="en" />
                    </Helmet>
                    <AppContext.Provider value={{ windowWidth: bounds ? bounds.width : 0, windowHeight: bounds ? bounds.height : 0 }}>
                    { children }
                    </AppContext.Provider>
                </ContainerDiv>        
            )}
        </Measure>
        )} />
)

export default Container