import React from 'react'
import styled from '@emotion/styled'

export enum Raised {
    None,
    Left,
    Right
}

type ContainerProps = {
    isRaised: boolean
}

type PolygonProps = {
    offset: number
}

const LeftContainer = styled.div`
    z-index: ${(props: ContainerProps) =>  props.isRaised ? 0 : -1};
    width: calc(100% + 1px);
    height: 100%;
    position: absolute;
    top: 0;
    left: 1px;
    margin-left: -1px;
    transition: filter 0.3s;
    ${(props: ContainerProps) => props.isRaised ? 'filter: drop-shadow(2px 2px 10px rgba(0,0,0,0.2))' : ''}
`

const RightContainer = styled.div`
    z-index: ${(props: ContainerProps) =>  props.isRaised ? 0 : -1};
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: filter 0.3s;
    ${(props: ContainerProps) => props.isRaised ? 'filter: drop-shadow(-2px -2px 10px rgba(0,0,0,0.2))' : ''}
`

const Left = styled.div`
    background-color: #3587E3;
    width: 100%;
    height: 100%;
    clip-path: ${(props: PolygonProps) => `polygon(0 0, ${70 + props.offset}% 0, ${30 + props.offset}% 100%, 0 100%)`};
`

const Right = styled.div`
    background-color: #1D70DC;
    width: 100%;
    height: 100%;
    clip-path: ${(props: PolygonProps) => `polygon(${70 - props.offset}% 0, 100% 0%, 100% 100%, ${30 - props.offset}% 100%)`};
`

const Background = ({ raised, offset }) => (
    <>
        <LeftContainer isRaised={raised == Raised.Left}>
            <Left offset={isNaN(offset) ? 0 : offset} />
        </LeftContainer>
        <RightContainer isRaised={raised == Raised.Right}>
            <Right offset={isNaN(offset) ? 0 : offset} />
        </RightContainer>
    </>
)

export default Background