export const Pointer = ({ className }: { className?: string }) => (
    <svg width="205" height="117.19059161704703" viewBox="0 0 205 117.19059161704703" className={className}>
        <defs id="SvgjsDefs2103"></defs>
            <g id="SvgjsG2104" transform="matrix(1.1039368358556603,0,0,1.1039368358556603,-2.6969407556264997,13.400668572562537)" fill="#ffffff">
                <path xmlns="http://www.w3.org/2000/svg" d="M27.293,12.695l45.43,41.219l-17.195,7.613l9.246,20.88l-5.526,2.446l-5.526,2.448l-9.247-20.879l-17.195,7.613  L27.293,12.695z M79.354,54.984c-0.363,2.252-1.826,4.146-3.91,5.072l-11.055,4.893l6.525,14.738  c1.498,3.386-0.035,7.359-3.421,8.862l-5.528,2.448l-5.527,2.445c-0.862,0.383-1.777,0.576-2.715,0.576  c-2.656,0-5.069-1.568-6.145-3.998l-6.527-14.739L29.996,80.18c-0.864,0.383-1.779,0.574-2.719,0.574  c-1.305,0-2.573-0.375-3.665-1.086c-1.911-1.246-3.052-3.352-3.052-5.633l0.016-61.343c0-2.652,1.569-5.063,4.002-6.142  c0.862-0.377,1.774-0.572,2.713-0.572c1.672,0,3.275,0.619,4.514,1.742l45.429,41.22C78.924,50.47,79.716,52.729,79.354,54.984z   M75.43,50.93L30.001,9.709c-0.756-0.685-1.727-1.044-2.709-1.044c-0.551,0-1.106,0.113-1.631,0.344  c-1.457,0.646-2.398,2.091-2.398,3.684l-0.016,61.343c0,1.364,0.689,2.637,1.832,3.379c0.664,0.433,1.43,0.653,2.198,0.653  c0.554,0,1.11-0.115,1.632-0.348l13.51-5.98l7.615,17.195c0.667,1.506,2.141,2.399,3.687,2.399c0.547,0,1.1-0.112,1.629-0.347  l5.528-2.447l5.524-2.448c2.035-0.901,2.955-3.282,2.055-5.317L60.846,63.58l13.508-5.982c1.246-0.551,2.131-1.697,2.349-3.043  C76.917,53.209,76.439,51.846,75.43,50.93z">
                </path>
            </g>
            <g id="SvgjsG2105" transform="matrix(4.205153632784875,0,0,4.205153632784875,102.47690922395053,13.610104117431305)" fill="#ffffff">
                <path d="M8.7 6 l0 9.32 c0 3.62 -2.1 4.92 -4.34 4.92 c-1.72 0 -3.04 -0.76 -3.76 -1.92 l1.04 -1.14 c0.56 0.84 1.46 1.46 2.66 1.46 c1.5 0 2.74 -0.76 2.74 -3.48 l0 -9.16 l1.66 0 z M24.36 12.96 c0.3 4.48 -2.76 7.24 -6.4 7.24 c-3.74 0 -7.16 -2.96 -7.16 -7.2 s3.5 -7.2 7.16 -7.2 c1.76 0 3.36 0.6 4.62 1.66 l-1.02 1.16 c-0.98 -0.78 -2.24 -1.24 -3.5 -1.24 c-2.86 0 -5.54 2.32 -5.54 5.62 s2.6 5.62 5.44 5.62 c2.48 0 4.46 -1.34 4.84 -4.22 l-4.08 0 l0 -1.44 l5.64 0 z">
                </path>
            </g>
    </svg>)